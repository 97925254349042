<template>
    <RouterView></RouterView>
</template>

<script>
import 'swiper/css';

const debounce = (fn, delay) => {
	let timer = null;

	return function () {
		let context = this;

		let args = arguments;

		clearTimeout(timer);

		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
};

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor(callback) {
		callback = debounce(callback, 16);
		super(callback);
	}
}; 
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
@import 'swiper/css';
/* 山河集 */
@import '//at.alicdn.com/t/c/font_1161655_cmzmrqeyp1c.css';
#app {
 height: 100%;
 width: 100%;
 font-size: 0.12rem;
}
@font-face {
    font-family: "siyuanheitiLigth";
    src: url('../public/fontFamily/siyuanheiti/SourceHanSansCN-Light.ttf');
}
@font-face {
    font-family: "siyuansongtiLigth";
    src: url('../public/fontFamily/siyuansongti/font1681.ttf');
}
body {

 /*适配底部黑条*/
  padding-bottom:constant(safe-area-inset-bottom);
  padding-bottom:env(safe-area-inset-bottom, 20px)
}
</style>
