import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path:'/',
        name:'山河集3D定制',
        component:()=>import('@/pages/homepage')
    }
]

const router = createRouter({
    history:createWebHashHistory(),
    routes
})

export default router